import { Image } from "antd";
import React, { useState } from "react";
import DetailsModal from "../detailsModal/DetailsModal";

const CompanyCard = ({ boardDetails ,type}) => {
  const [isModalOpen, setIsmodalOpen] = useState(false);

  return (
    <div
      className="flex justify-start items-center flex-col lg:flex-row gap-2 h-[280px] lg:max-h-[150px] lg:h-[150px] p-5 border-b bg-[#001529]   shadow lg  cursor-pointer  rounded-[16px] w-full "
      onClick={() => setIsmodalOpen(!isModalOpen)}
    >
      <div className="flex flex-col justify-center items-center text-center lg:text-start lg:items-start   gap-3 2xl:w-[30%] lg:w-[30%] lg:border-r h-full">
        <p className=" capitalize text-sm font-bold   w-full text-center lg:text-start  text-[#9b7c27]">
         {boardDetails?.name}
        </p>
       
      </div>

      <div className="w-full flex flex-col justify-center items-start gap-2 lg:gap-1 px-1  text-[#9b7c27]  h-full ">
     {
      type === 'joint' ? null : (
<p className=" justify-start lg:gap-2 items-start lg:items-center text-[14px] text-[grey]  lg:border-b flex w-full flex-col lg:flex-row ">
          <span className="min-w-[150px] ">{type === 'estate'? "Date of birth (Deceased) :" : 'Date Of Registration :'}{''}</span>
          <span className="text-white capitalize text-[14px]  ">
            {boardDetails?.dateOfRegistration?.slice(0, 10)}
          </span>
        </p>
      )
     }
        
        <p className=" justify-start lg:gap-2 lg:items-center items-start text-[14px] text-[grey]  border-b flex w-full flex-col lg:flex-row ">
          <span className="min-w-[150px] ">Address: </span>
          <span className="text-white capitalize text-[14px]  ">
            {boardDetails?.address}
          </span>
        </p>
        <p className=" justify-start lg:gap-2 lg:items-center items-start text-[14px] text-[grey]  border-b flex w-full flex-col lg:flex-row ">
          <span className="min-w-[150px] ">{type === 'estate'? "Mother's maiden name" : type === 'joint' ? 'Contact' : 'Rec No :'} </span>
          <span className="text-white capitalize text-[14px]  ">
            {boardDetails?.registrationNumber}
          </span>
        </p>
      </div>

      <DetailsModal
        id={boardDetails?.id}
        setIsmodalOpen={setIsmodalOpen}
        isModalOpen={isModalOpen}
        type={type}
      />
    </div>
  );
};

export default CompanyCard;
