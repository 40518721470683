import { Image } from "antd";
import React from "react";

const UploadPreview = ({ imageUrl, name }) => {
 
  return (
    <div className="flex justify-center items-center gap-5 flex-col border-b w-full p-5">
      <div className="text-center text-lg capitalize">{name}</div>
      <div className=" w-[100px] h-[100px] ">
        <Image
          src={imageUrl}
          alt={name}
          className=" w-full h-full  object-cover  bg-white !z-10"
        />
      </div>
    </div>
  );
};

export default UploadPreview;
