import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, Spin, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import adminServices from "../../services/httpServices";
import NotificationService from "../../NotificationService";

const EditSlider = ({ id }) => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isloading, setIsloading] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [fileList, setFileList] = useState([]);
  const [uploadedimage, setUploadedImage] = useState(null);
// console.log(values);
  const fetch = async () => {
    setIsloading(true);
    try {
      if (id) {
        const res = await adminServices.getSliderById(id);
    // console.log(res);
        if (res?.data) {
          form.setFieldsValue({
            headings1: res?.data?.headings1,
            headings2: res?.data?.headings2,
            content: res?.data?.content,
           
          });
          setImagePath(res?.data?.imageUrl)
          setIsloading(false);
        }
      }
      return;
    } catch (error) {
 NotificationService.show(error.message, "error");
        setIsloading(false);
  // console.log(error);
    }
  };


  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleImageUpload = (info) => {
// console.log(info);
    if (info.file.status === "done") {
      const file = info.file.originFileObj;
      setFileList(info.fileList);
  // console.log(file);
      setUploadedImage(file);
    }
  };
  const imageUpload = async () => {
    if (uploadedimage) {
      setIsloading(true);
      try {
        let data = new FormData();
        data.append("File", uploadedimage);
        const res = await adminServices.fileUpload(data);
        // console.log(res);
        setImagePath(res.data?.path);
        setIsloading(false);
      } catch (error) {
 NotificationService.show(error.message, "error");
    // console.log(error);
        setIsloading(false);
      }
    }
    return;
  };

  const editHandler = async() =>{
    delete values.photo55
    setIsloading(true)
    try {
       await adminServices.EditSliderById(parseInt(id),{
        ...values,
        imageUrl : imagePath,
        isactive : true
       });
        setIsloading(false)

    } catch (error) {
 NotificationService.show(error.message, "error");
        setIsloading(false)
        // console.log(error)
    }
  }

  useEffect(() => {
    imageUpload();
  }, [uploadedimage]);
  useEffect(() => {
    fetch();
  }, [id]);
  return (
    <div>
      <Spin
        spinning={isloading}
        delay={500}
        size="large"
        tip={"Fetching Uploaded Slider Data...."}
        className=" text-[#9b7c27] font-bold"
      >
        <Form
          form={form}
          // initialValues={initialValues}
          className="bg-gray-50 p-8 rounded border"
          layout="vertical"
          onFinish={() =>
            editHandler()
          }
        >
          <div className="flex flex-col gap-3">
            <Form.Item
              name={"headings1"}
              className="mb-3 flex-1"
              label="first Heading"
              rules={[{ required: true }]}
            >
              <Input placeholder="heading1" />
            </Form.Item>
            <Form.Item
              name={"headings2"}
              className="mb-3 flex-1"
              label="second Heading"
              rules={[{ required: true }]}
            >
              <Input placeholder="heading2" />
            </Form.Item>

            <Form.Item
              name={"content"}
              className="mb-3 flex-1"
              label="content"
              rules={[{ required: true }]}
            >
              <TextArea placeholder="content" />
            </Form.Item>
            <div >
                <Image src={imagePath} alt="uploaded Image" className="object-cover" />
            </div>
            <Form.Item
              // rules={[{ required: true, message: "upload Image" }]}
              className="mb-1 flex-1 flex justify-start items-start"
              name="photo55"
              label="Upload Image"
            >
              <Upload
                onChange={handleImageUpload}
                customRequest={dummyRequest}
                maxCount={1} accept=".svg,.png,.jpg,.jpeg"
                // fileList={[values?.photo55]}
                className=""
              >
                <Button icon={<UploadOutlined />}>Upload Photo</Button>
              </Upload>
            </Form.Item>
          </div>

          <Form.Item rules={[{ required: true }]} className="mb-1 flex-1">
            <Button
              htmlType="submit"
              className="bg-[#001529] text-white  mt-2"
              // onSubmit={}
            >
              Save 
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default EditSlider;
