import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { BsFillCloudUploadFill, BsImageFill } from "react-icons/bs";
import { CiWarning } from "react-icons/ci";
import adminServices from "../../services/httpServices";
import TextArea from "antd/es/input/TextArea";
// import { DefaultTime } from "../../helpers/helpers";
import { FaPersonChalkboard } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import EditBoard from "../../components/editBoard";
import { currentDateTime } from "../../helpers/helpers";
import NotificationService from "../../NotificationService";

const Board = () => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [tableData, setTableData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [boardForDirectors, setBoardOfDirectors] = useState([]);
  const [mgtStaff, setMgtStaff] = useState([]);
  const [isModalOpenForm, setIsModalOpenForm] = useState(false);
  const [deleteID, setdeleteID] = useState(undefined);
  // const [ebook, setEbook] = useState(null);
  const [selectCate, setSelectCate] = useState([]);
  const [postCate, setpostCate] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const { pathname } = useLocation();
  const [postData, setPostData] = useState([]);
  const [uploadedimage, setUploadedImage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [EditId, setEditId] = useState(undefined);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [businessNewsData, setBusinessNewsData] = useState([]);
  // const currentDateTime = new Date().toISOString();

  const initialValues = {
    id: 0,
    title: "",
    name: "",
    description: "",
    imgUrl: "",
    dateCreated: currentDateTime,
    videoUrl: "",
  };

  const columns = [
    {
      title: <p className="text-center">S/N</p>,
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text) => <p className="text-center">{text}</p>,
    },

    {
      title: <p className="text-center">Title</p>,
      dataIndex: "title",
      key: "title",
      width: 150,
      render: (text) => (
        <p className=" overflow-ellipsis capitalize">{text}</p>
      ),
    },
    {
      title: <p className="text-center">Name</p>,
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (text) => (
        <p className="text-center overflow-ellipsis capitalize">{text}</p>
      ),
    },
    {
      title: <p className="text-center">Description</p>,
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <p className="text-justify overflow-ellipsis">{text}</p>
      ),
    },
    {
      title: <p className="text-center">Category</p>,
      dataIndex: "teamType",
      key: "teamType",
      render: (text) => (
        <p className="text-justify overflow-ellipsis">{text}</p>
      ),
    },
    {
      title: <p className="text-center">Staff Image</p>,
      dataIndex: "imgUrl",
      width: 200,
      key: "imgUrl",
      render: (record) => (
        <div className="w-[200px] h-[200px] rounded-xl flex justify-center items-center  ">
          <img
            src={record}
            alt="slider "
            className="object-fill rounded-[50%] flex justify-center items-center w-full h-full"
          />
        </div>
      ),
    },

    {
      title: <p className="text-center">ACTION</p>,
      width: 100,
      // dataIndex: "header",
      // key: "header",
      render: (record) => (
        <div className="flex justify-center items-center gap-5">
          <RiDeleteBin6Fill
            className="text-[20px] cursor-pointer"
            onClick={() => actionDeleteModalOpen(record?.id)}
          />
          <FaEdit
            className="text-[20px] cursor-pointer"
            onClick={() => {
              setIsEditModalOpen(true);
              setEditId(record?.id);
            }}
          />
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      title: <p className="text-center">S/N</p>,
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text) => <p className="text-center">{text}</p>,
    },

    {
      title: <p className="text-center">Title</p>,
      dataIndex: "title",
      key: "title",
      width: 150,
      render: (text) => (
        <p className=" overflow-ellipsis capitalize">{text}</p>
      ),
    },
    {
      title: <p className="text-center">Description</p>,
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <p className="text-justify overflow-ellipsis">{text}</p>
      ),
    },
    {
      title: <p className="text-center">Category</p>,
      dataIndex: "categoryName",
      key: "categoryName",
      render: (text) => (
        <p className="text-justify overflow-ellipsis">{text}</p>
      ),
    },
    {
      title: <p className="text-center">Post Image</p>,
      dataIndex: "imgUrl",
      width: 200,
      key: "imgUrl",
      render: (record) => (
        <div className="w-[200px] h-[200px] rounded-xl flex justify-center items-center  ">
          <Image
            src={record}
            alt="slider "
            className="object-cover rounded-xl flex justify-center items-center w-full h-full"
          />
        </div>
      ),
    },
    {
      title: <p className="text-center">Post Video</p>,
      // dataIndex: "vidoeUrl",
      width: 200,
      // key: "videoUrl",
      render: (record) => (
        <div
          className="w-[200px] rounded-xl flex justify-center items-center  "
          onClick={() => console.log(record)}
        >
          <iframe
            width="200"
            height="200"
            src={record?.vidoeUrl || record?.imgUrl}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      ),
    },

    {
      title: <p className="text-center">ACTION</p>,
      width: 80,
      // dataIndex: "header",
      // key: "header",
      render: (record) => (
        <Button
          type="text"
          className="uppercase text-white  bg-[red]"
          onClick={() => actionDeleteModalOpen(record?.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  const fetchPost = async () => {
    setIsloading(true);
    try {
      const res = await adminServices.getAllPostData();

      const mappedTableData = res?.data
        ?.filter((value) => value.isactive === true)
        .map((value, index) => {
          return { ...value, key: index };
        });

      setPostData(mappedTableData);
      // setBusinessNewsData(BusinessNewsData)
    } catch (error) {
      NotificationService.show(error.message, "error");
  // console.log(error);
    }
  };

  const fetchCategorys = async () => {
    try {
      setIsloading(true);
      const res = await adminServices.getStaffCategory();
      setSelectCate(res.data);
      setIsloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
      setIsloading(false);
    }
  };

  const fetchPostCate = async () => {
    try {
      setIsloading(true);
      const res = await adminServices.getAllPostType();
      setpostCate(res.data);
      setIsloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
      setIsloading(false);
    }
  };

  const fetch = async () => {
    setIsloading(true);
    try {
      const response = await adminServices.getAllBoard();
  // console.log(response);
      const mappedTableData = response?.data
        ?.filter((value) => value.isActive === true)
        .map((value, index) => {
          return { ...value, key: index };
        });
      // const filteredBoardOfDirectors = mappedTableData.filter((value) => {
      //   return value.teamType === "Board";
      // });

      // const filteredMgt = mappedTableData.filter((value) => {
      //   return value.teamType === "Teams";
      // });
      // setBoardOfDirectors(filteredBoardOfDirectors);
      // setMgtStaff(filteredMgt);
      setTableData(mappedTableData);
      setIsloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
  // console.log(error);
      setIsloading(false);
      setTableData([]);
    }
  };

  const actionDeleteModalOpen = (id) => {
    setIsModalOpen(true);
    setdeleteID(id);
  };

  const OnCancelHandler = () => {
    setIsModalOpenForm(false);
    setIsModalOpen(false);
    setIsloading(false);
    setFileList([]);
    setdeleteID(undefined);
    setUploadedImage(null);
    form.setFieldsValue(initialValues);
  };

  const actionDeleteHandler = async (id) => {
    try {
      pathname === "/Post" || pathname === "/business-news"
        ? await adminServices.deletePost(deleteID)
        : await adminServices.mgtDelete(deleteID);
      setIsModalOpen(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
  // console.log(error);
    }
  };

  const createSliderHandler = async (data) => {
    delete values?.photo55;
    setIsloading(true);
    try {
      const res = await adminServices.createNewBoardMember(data);
      if (res.status === 200) {
        OnCancelHandler();
      }
    } catch (error) {
      NotificationService.show(error.message, "error");
      setIsloading(false);
    }
  };

  const createPostHandler = async (data) => {
    setIsloading(true);
    try {
      const res = await adminServices.Post(data);
      if (res.status === 200) {
        OnCancelHandler();
      }
    } catch (error) {
      NotificationService.show(error.message, "error");
      setIsloading(false);
    }
  };

  const imageUpload = async () => {
    if (uploadedimage) {
      setIsloading(true);
      try {
        let data = new FormData();
        data.append("File", uploadedimage);
        const res = await adminServices.fileUpload(data);
        // console.log(res);
        setImagePath(res.data?.path);
        setIsloading(false);
      } catch (error) {
        NotificationService.show(error.message, "error");
    // console.log(error);
        setIsloading(false);
      }
    }
    return;
  };

  const handleImageUpload = (info) => {
// console.log(info);
    if (info.file.status === "done") {
      const file = info.file.originFileObj;
      setFileList(info.fileList);
  // console.log(file);
      setUploadedImage(file);
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  useEffect(() => {
    imageUpload();
  }, [uploadedimage]);

  useEffect(() => {
    fetch();
    fetchCategorys();
    fetchPost();
    fetchPostCate();
  }, [isModalOpenForm, isEditModalOpen, isModalOpen, pathname]);

  return (
    <div>
      <div className="flex lg:flex-row flex-col mb-6 lg:mb-0  justify-between items-start  ">
        <p className="flex lg:justify-center items-center gap-2 lg:text-[30px] text-[20px] font-[500] leading-loose">
          <span>
            <FaPersonChalkboard className="" />
          </span>
          {pathname === "/boards"
            ? "Board of Directors & Management Staff"
            : pathname === "/business-news"
            ? "Business news"
            : "Homepage Post Data"}
        </p>
        <Button
          type="text"
          className="uppercase font-bold text-[16px] justify-center items-center flex 
           gap-2 bg-[#001529] text-white py-5"
          onClick={() => setIsModalOpenForm(true)}
        >
          <span>
            <BsFillCloudUploadFill className="text-[20px]" />
          </span>
          Upload
        </Button>
      </div>
      <Spin
        spinning={isloading}
        delay={500}
        size="large"
        tip={"Fetching Table Data...."}
        className=" text-[#9b7c27] font-bold"
      >
        <Table
          dataSource={pathname === "/boards" ? tableData : postData}
          columns={
            pathname === "/Post" 
              ? columns2
              : columns
          }
          // size="small"
          className=" w-full "
          scroll={{ x: 400 }}
        />
      </Spin>

      <Modal
        open={isModalOpen}
        centered={true}
        okText="save"
        cancelText="Make Changes"
        onOk={actionDeleteHandler}
        onCancel={OnCancelHandler}
        footer={[
          <div
            key={2}
            className=" w-[80%] mx-auto flex justify-center items-center"
          >
            <Button
              key="back"
              htmlType="submit"
              className="w-full bg-[] text-[#001529] font-bold"
              onClick={OnCancelHandler}
            >
              Make Changes
            </Button>
            <Button
              key="submit"
              className="w-full bg-[red] text-[white] font-bold"
              onClick={actionDeleteHandler}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <div className=" w-[80%] mx-auto flex flex-col justify-center items-center text-center gap-5">
          <CiWarning className="text-[40px] text-[#9b7c27]" />
          <p className="text-[18px] leading-[21px] flex flex-col font-bold gap-1 ">
            This Action is Irreversible
            <span className="text-[14px] font-normal">
              Are You Sure You Want To Delete
            </span>
          </p>
        </div>
      </Modal>

      <Modal
        open={isModalOpenForm}
        centered={true}
        onOk={() => actionDeleteHandler}
        onCancel={() => setIsModalOpenForm(false)}
        footer={[
          <div key={2} className="  flex justify-center items-center"></div>,
        ]}
        className="lg:!w-[40%] !w-[90%] !h-[50%]"
      >
        <div className="flex flex-col justify-center items-center text-center mt-8 !w-full">
          <div className="w-full">
            <Spin
              tip={pathname === "/boards" ? "fetching" : "Creating New Post "}
              spinning={isloading}
              className="text-[#9b7c27] "
            >
              {pathname === "/Post" ? (
                <Form
                  form={form}
                  initialValues={initialValues}
                  className="bg-gray-50 p-8 rounded border w-full"
                  layout="vertical"
                  onFinish={() =>
                    createPostHandler({
                      ...values,
                      id: 0,
                      // title: "string",
                      // description: "string",
                      imgUrl: imagePath,
                      // videoUrl: "string",
                    
                      isActive: true,
                      dateCreated: currentDateTime,
                    })
                  }
                >
                  <div className="flex flex-col gap-3">
                    <Form.Item
                      name={"title"}
                      className="mb-3 flex-1"
                      label="Title"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="title" />
                    </Form.Item>
                    <Form.Item
                      name={"categoryId"}
                      className="mb-3 flex-1"
                      label="Select post Category"
                      rules={[
                        { required: true, message: "Select Post Category" },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Select Post Category"
                        className="text-gray-600"
                      >
                        {postCate.map(({ id, name }, index) => (
                          <Select.Option value={id} key={index}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={"description"}
                      className="mb-3 flex-1"
                      label="Description"
                      rules={[{ required: true }]}
                    >
                      <TextArea placeholder="description" />
                    </Form.Item>

                    <Form.Item
                      rules={[{ required: true, message: "upload Image" }]}
                      className="mb-1 flex-1 flex justify-start items-start"
                      name="photo55"
                      label="Upload Image"
                    >
                      <Upload
                        onChange={handleImageUpload}
                        customRequest={dummyRequest}
                        maxCount={1}
                        accept=".svg,.png,.jpg,.jpeg"
                        // fileList={fileList}
                        className=""
                      >
                        <Button icon={<UploadOutlined />}>Upload Photo</Button>
                      </Upload>
                    </Form.Item>
                  </div>
                  <Form.Item
                    name={"videoUrl"}
                    className="mb-3 flex-1"
                    label="Video Url"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="https://" />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true }]}
                    className="mb-1 flex-1"
                  >
                    <Button
                      htmlType="submit"
                      className="bg-[#001529] text-white  mt-2"
                      // onSubmit={}
                    >
                      Create new Post
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <Form
                  form={form}
                  initialValues={initialValues}
                  className="bg-gray-50 p-8 rounded border w-full"
                  layout="vertical"
                  onFinish={() =>
                    createSliderHandler({
                      ...values,
                      dateCreated: currentDateTime,
                      imgUrl: imagePath,
                    })
                  }
                >
                  <div className="flex flex-col gap-3">
                    <Form.Item
                      name={"title"}
                      className="mb-3 flex-1"
                      label="Title"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="title" />
                    </Form.Item>
                    <Form.Item
                      name={"name"}
                      className="mb-3 flex-1"
                      label="Name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="name" />
                    </Form.Item>

                    <Form.Item
                      name={"description"}
                      className="mb-3 flex-1"
                      label="Description"
                      rules={[{ required: true }]}
                    >
                      <TextArea placeholder="description" />
                    </Form.Item>
                    <Form.Item
                      name={"categoryId"}
                      className="mb-3 flex-1"
                      label="Select staff Category"
                      rules={[
                        { required: true, message: "Select staff Category" },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Select staff Category"
                        className="text-gray-600"
                      >
                        {selectCate.map(({ id, name }, index) => (
                          <Select.Option value={id} key={index}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true, message: "upload Image" }]}
                      className="mb-1 flex-1 flex justify-start items-start"
                      name="photo55"
                      label="Upload Image"
                    >
                      <Upload
                        onChange={handleImageUpload}
                        customRequest={dummyRequest}
                        maxCount={1}
                        accept=".svg,.png,.jpg,.jpeg"
                        // fileList={fileList}
                        className=""
                      >
                        <Button icon={<UploadOutlined />}>Upload Photo</Button>
                      </Upload>
                    </Form.Item>
                  </div>

                  <Form.Item
                    rules={[{ required: true }]}
                    className="mb-1 flex-1"
                  >
                    <Button
                      htmlType="submit"
                      className="bg-[#001529] text-white  mt-2"
                      // onSubmit={}
                    >
                      Create new member
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Spin>
          </div>
        </div>
      </Modal>

      <Modal
        open={isEditModalOpen}
        centered={true}
        okText="save"
        cancelText="Make Changes"
        onOk={actionDeleteHandler}
        onCancel={() => {
          setIsEditModalOpen(false);
          setEditId(undefined);
        }}
        footer={null}
      >
        <EditBoard id={EditId} setIsEditModalOpen={setIsEditModalOpen} />
      </Modal>
    </div>
  );
};

export default Board;
