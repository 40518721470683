import React, { useEffect, useState } from "react";
import BoardCard from "../../components/boardOfdirectors";
import adminServices from "../../services/httpServices";
import { Pagination, Spin } from "antd";
import { useLocation } from "react-router-dom";
import CompanyCard from "../../components/boardOfdirectors/companyCard";
import NotificationService from "../../NotificationService";

const ITEMS_PER_PAGE = 10;
const IndividualAccount = () => {
  const { pathname } = useLocation();
  const [allAccounts, setAllAccount] = useState([]);
  const [MinorAccts, setMinorAcct] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPageCount = Math.ceil(allAccounts?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = currentPage * ITEMS_PER_PAGE;
  const accountsForCurrentPage = allAccounts?.slice(startIndex, endIndex);
  const accountForMinor = MinorAccts?.slice(startIndex, endIndex);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetch = async () => {
    setIsloading(true);
    try {
      const res = await adminServices.getAllAccountOpening(
        pathname === "/IndividualAccount"
          ? 1
          : pathname === "/minor_Account"
          ? 4
          : pathname === "/corporate_Account"
          ? 3
          : pathname === "/estate_Account"
          ? 2
          : pathname === "/joint_Account"
          ? 5
          : null
      );


  // console.log(res.data);
      setAllAccount(
        pathname === "/IndividualAccount" || pathname === "/minor_Account"
          ? res.data[1]?.personalDetailsWithCompanyInfos
          : res?.data
      );
      setIsloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
      setIsloading(false);
  // console.log(error);
    }
  };

  useEffect(() => {
    fetch();
  }, [pathname]);
  return (

      <div>
        <p className="text-xl font-bold ">
          {" "}
          {pathname === "/IndividualAccount"
            ? "Individual Account Opening Details"
            : pathname === "/minor_Account"
            ? "Minor Account Opening Details"
            : pathname === "/corporate_Account"
            ? "Corporate Account Opening Details"
            : pathname === "/estate_Account"
            ? "Estate Account Opening Details"
            : pathname === "/joint_Account"
            ? "Joint Account Opening Details"
            : null}
        </p>

        <div className="grid  justify-between items-center flex-wrap gap-3 lg:grid-cols-1  md:grid-cols-1  xl:grid-cols-2  p-5 ">
      {
        isloading ? ( <Spin spinning={isloading} tip={'fetching...'} />) :(
          accountsForCurrentPage?.map((value, index) =>
            pathname === "/IndividualAccount" ||
            pathname === "/minor_Account" ? (
              <BoardCard key={index} boardDetails={value?.personalDetailsVM} />
            ) : (
              <CompanyCard
                key={index}
                boardDetails={value?.companyVM}
                type={
                  pathname === "/estate_Account"
                    ? "estate"
                    : pathname === "/joint_Account"
                    ? "joint"
                    : "company"
                }
              />
            )
          )
        )
      }
      
         
        </div>
        <Pagination
          current={currentPage}
          total={allAccounts?.length}
          pageSize={ITEMS_PER_PAGE}
          onChange={handlePageChange}
        />
      </div>
    
  );
};

export default IndividualAccount;
