import { Modal } from "antd";
import React, { useState } from "react";
import Details from "./details";
import { useLocation } from "react-router-dom";
import CompanyDetails from "./companyDetails";

const DetailsModal = ({ isModalOpen, setIsmodalOpen, id,type }) => {
  const {pathname} = useLocation();
  // console.log(pathname,'pathname: ');
  return (
    <Modal
      open={isModalOpen}
      centered={true}
      onCancel={() => setIsmodalOpen(false)}
      footer={null}
      className="lg:!w-[70%] w-[90%] !h-[90%] !overflow-auto !z-[999]"
    >
      {
        pathname === '/IndividualAccount' || pathname === "/minor_Account" ?   <Details id={id} /> : <CompanyDetails id={id} type={type} />
      }
     
    </Modal>
  );
};

export default DetailsModal;
