import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { BiSolidUser } from "react-icons/bi";
import { FaGlobeEurope } from "react-icons/fa";
import { LuClipboardPaste } from "react-icons/lu";
import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Progress,
  Spin,
  Table,
  Tag,
  Upload,
  message,
} from "antd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import adminServices from "../../services/httpServices";
import NotificationService from "../../NotificationService";
import cancelIcon from "../../Assets/times-circle.svg";
import ImageUpload from "../../Assets/imageUpload.svg";

const { Dragger } = Upload;

function formatSize(bytes) {
  if (bytes < 1024) return bytes + " B";
  else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + " KB";
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(1) + " MB";
  else return (bytes / 1073741824).toFixed(1) + " GB";
}
const DashBoard = () => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [allTableData, setallTableData] = useState([]);
  const [topGainer, setTopGainer] = useState([]);
  const [TopLoser, setTopLoser] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isModalloading, setIsModalloading] = useState(false);
  const [refresh, setrefresh] = useState(false);
  const [isModalOpenForm, setIsModalOpenForm] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadedImage, setuploadedImage] = useState(null);
  const [isModalLoading, setisModalLoading] = useState(false);
  const [progress, setprogress] = useState(0);
  const [uploadLimitError, setUploadlimitError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  const onSucessFullyFileUpload = async (file) => {
    setUploadedFile(file);
  };

  const fileUploadHandler = ({ file, onSuccess }) => {
    onSuccess(onSucessFullyFileUpload(file));
  };

  const handleImageUpload = (info, setUploadedImage) => {
    setprogress(info?.file?.percent);

    setUploadedImage(info.file?.name);
    if (info.file.size < 100000000) {
      setFileList(info.fileList);

      if (info.file.status === "done" && !uploadLimitError) {
        const file = info.file.originFileObj;
        setFileList(info.fileList);
        // console.log(file);
        setUploadedImage(file);
      }
    } else setUploadlimitError(true);
  };

  const removeUploadedImagesHandler = (uid) => {
    const findFile = fileList.find((file) => uid === file.uid);

    if (findFile) {
      const newFileList = fileList.filter((file) => file.uid !== uid);
      // setUploadedImage(null);

      setFileList(newFileList);
    }

    return fileList;
  };

  const fetch = async () => {
    setIsloading(true);
    try {
      const res = await adminServices.GetAllStocks();
      const mappedData = res?.data
        ?.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.symbol === item.symbol)
        )
        .map((value) => {
          return {
            ...value,
            key: value.id,
          };
        });
      setallTableData(mappedData);

      const topLoserRes = res?.data
        ?.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.symbol === item.symbol)
        )
        .filter((value) => value.change && value.percentageChange < 0)
        .filter((value, index) => index < 5)
        .map((value) => {
          return { ...value, key: value.id };
        });
      const TopGainerRes = res?.data
        ?.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.symbol === item.symbol)
        )
        .filter((value) => value.change && value.percentageChange > 0)
        .filter((value, index) => index < 5)
        .map((value) => {
          return { ...value, key: value.id };
        });
      setTopLoser(topLoserRes);
      setTopGainer(TopGainerRes);
      setIsloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
      setallTableData([]);
      setTopLoser([]);
      setTopGainer([]);
      setIsloading(false);
    }
  };

  const importPrice = async () => {
    setisModalLoading(true);
    let data = new FormData();
    data.append("file", uploadedFile);
    try {
      const res = await adminServices.importPrice(data);
      setrefresh(!refresh)
      setisModalLoading(false);
      setIsModalOpenForm(false);
      setUploadedFile(null);
      setFileList([]);
    } catch (error) {
      NotificationService.show(error.message, "error");
      setisModalLoading(false);
      setIsModalOpenForm(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [refresh]);

  const columns = [
    {
      title: "Company",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "PCLOSE",
      dataIndex: "pClose",
      key: "pClose",
    },
    {
      title: "OPEN",
      key: "openingPrice",
      dataIndex: "openingPrice",
    },
    {
      title: "HIGH",
      dataIndex: "high",
      key: "high",
    },
    {
      title: "LOW",
      dataIndex: "low",
      key: "low",
    },
    {
      title: "CLOSE",
      key: "close",
      dataIndex: "close",
    },
    {
      title: "CHG",
      dataIndex: "percentageChange",
      key: "percentageChange",
      render: (text) => (
        <div className=" w-full flex justify-center items-center">
          <Tag
            color={`${text === 0 ? "blue" : text < 0 ? "red" : "green"}`}
            className=" lg:min-w-[60%] lg:mx-auto text-center"
          >
            {text < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />}
            {text?.toLocaleString()}
          </Tag>
        </div>
      ),
    },

    {
      title: "TRD",
      dataIndex: "trades",
      key: "trades",
    },
    {
      title: "VOL",
      key: "volume",
      dataIndex: "volume",
      render: (text) => <p> {text.toLocaleString()}</p>,
    },
    {
      title: "VAL(₦)",
      dataIndex: "value",
      key: "value",
      render: (text) => <p> {text.toLocaleString()}</p>,
    },
    {
      title: "Trade Date",
      key: "tradeDate",
      dataIndex: "tradeDate",
      // width: 120,
      render: (text) => <p> {text?.split("T")[0]}</p>,
    },
  ];

  const GainerColumns2 = [
    {
      title: "Company",
      dataIndex: "symbol",
      width: 100,
      key: "symbol",
    },
    {
      title: <p className=" text-center"> PCLOSE</p>,
      dataIndex: "pClose",
      key: "pClose",
      render: (text) => <p className=" text-center"> {text}</p>,
    },

    {
      title: <p className=" text-center">CLOSE</p>,
      key: "close",
      dataIndex: "close",
      render: (text) => <p className=" text-center"> {text}</p>,
    },
    {
      title: <p className=" text-center">CHG </p>,
      dataIndex: "percentageChange",
      key: "percentageChange",
      render: (text) => (
        <div className=" w-full flex justify-center items-center">
          <Tag
            color={`${text < 0 ? "red" : "green"}`}
            className=" lg:w-[60%] lg:mx-auto text-center"
          >
            {text < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />}
            {text}
          </Tag>
        </div>
      ),
    },
    {
      title: "Trade Date",
      key: "tradeDate",
      dataIndex: "tradeDate",
      // width: 120,
      render: (text) => <p> {text?.split("T")[0]}</p>,
    },
  ];

  const columns3 = [
    {
      title: "EQTY",
      dataIndex: "EQTY",
      key: "EQTY",
      width: 100,
    },
    {
      title: "PCLOSE",
      dataIndex: "PCLOSE",
      key: "PCLOSE",
    },

    {
      title: "CLOSE",
      key: "CLOSE",
      dataIndex: "CLOSE",
    },
    {
      title: "LOSS",
      dataIndex: "LOSS",
      key: "LOSS",
      render: (text) => <p className="text-[RED]">{text}</p>,
    },
    {
      title: "%LOSS",
      key: "LOSS_PER",
      dataIndex: "LOSS_PER",
      render: (text) => <p className="text-[RED]">{text}</p>,
    },
    {
      title: "Close Date",
      key: "CLOSEDATE",
      dataIndex: "CLOSEDATE",
      width: 120,
    },
  ];
  return (
    <div className=" grid grid-cols-1 gap-5">
      <Button
        type="default"
        className="bg-[#001529] text-white lg:w-[25%]  ml-auto w-full"
        size="large"
        onClick={() => {
          setrefresh(true);
          setIsModalOpenForm(true);
        }}
      >
        Create Market
      </Button>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 ">
        <div
          data-aos="flip-down"
          className=" flex bg-[#33414E] text-white font-bold justify-start items-center"
        >
          <div className=" lg:p-5 p-2 text-[55px]">
            <BiSolidUser />
          </div>
          <div className=" justify-center items-center p-1  grid text-[18px]">
            <p className="text-center">1</p>
            <p>TOTAL ADMINISTRATOR</p>
          </div>
        </div>

        <div
          data-aos="flip-down"
          className=" flex bg-[#95B75D] text-white font-bold justify-start items-center"
        >
          <div className=" lg:p-5 p-2 text-[55px]">
            <FaGlobeEurope />
          </div>
          <div className=" justify-center items-center p-1 flex flex-col  w-full text-[18px]">
            <p className="text-center">1</p>
            <p>TOTAL POSTS</p>
          </div>
        </div>

        <div
          data-aos="flip-down"
          className=" flex bg-[#3FBAE4] text-white font-bold justify-start items-center"
        >
          <div className=" lg:p-5 p-2 text-[55px]">
            <LuClipboardPaste />
          </div>
          <div className=" justify-center items-center p-1 flex flex-col  w-full text-[18px]">
            <p className="text-center">1</p>
            <p>TOTAL CUSTOMER</p>
          </div>
        </div>
      </div>

      <div className="w-[98%] mx-auto flex flex-col gap-10">
        <div>
          <p className="font-bold text-[18px] capitalize leading-loose py-2 text-center">
            current Price List
          </p>
          <div
            data-aos="zoom-in-up"
            className="shadow xl bg-white flex flex-col justify-center items-center "
          >
            <Table
              dataSource={allTableData}
              columns={columns}
              loading={isloading}
              // size="small"
              className=" w-full "
              scroll={{ x: 400 }}
            />

            <div className="text-sm border bg-[#e4e4e4] w-full flex-wrap gap-2 py-3 px-2 flex justify-start items-start font-[500] leading-loose">
              <p className="font-bold text-[#b49132]">LEGEND : </p>
              <p className="">
                {" "}
                PCLOSE - Previous Close Price; <span> </span> CHG - Change; RED
                - Loss; GREEN - Gain; BLUE - No Change
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 justify-center items-start gap-10">
          <div>
            <p className="font-bold text-[18px] capitalize leading-loose py-2 text-center">
              Top Gainers
            </p>

            <div
              data-aos="zoom-in-up"
              className="shadow-xl  flex flex-col justify-center items-center"
            >
              <Table
                dataSource={topGainer}
                columns={GainerColumns2}
                loading={isloading}
                // size="small"
                className=" w-full py-2"
                scroll={{ x: 400 }}
              />

              <div className="text-sm border bg-[#e4e4e4] w-full flex flex-col  md:flex-row gap-2 py-3 px-2 font-[500] leading-loose">
                <p className="font-bold text-[#b49132] text-center  ">
                  LEGEND :{" "}
                </p>
                <div className="flex flex-col md:flex-row gap-3">
                  <p> PCLOSE - Previous Close Price; </p>
                  <p>GREEN - Gain;</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <p className="font-bold text-[18px] capitalize leading-loose py-2 text-center">
              Top Losser
            </p>

            <div
              data-aos="zoom-in-up"
              className="shadow-xl  flex flex-col justify-center items-center"
            >
              <Table
                dataSource={TopLoser}
                columns={GainerColumns2}
                loading={isloading}
                // size="small"
                className="dave2 w-full py-2"
                scroll={{ x: 400 }}
              />

              <div className="text-sm border bg-[#e4e4e4] w-full flex flex-col  md:flex-row gap-2 py-3 px-2 font-[500] leading-loose">
                <p className="font-bold text-[#b49132] text-center  ">
                  LEGEND :{" "}
                </p>
                <div className="flex flex-col md:flex-row gap-3">
                  <p> PCLOSE - Previous Close Price; </p>
                  <p>GREEN - LOSS;</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={isModalOpenForm}
        centered={true}
        closable={false}
        onCancel={() => {
          setIsModalOpenForm(false);
          setUploadedFile(null);
          setFileList([]);
        }}
        footer={null}
        closeIcon={false}
        destroyOnClose={true}
      >
        <Spin spinning={isModalLoading} tip={"Uploading....."}>
          <div className="p-[16px] bg-[#F2F2F2] rounded-[16px] ">
            <Dragger
              onChange={(file) => handleImageUpload(file, setuploadedImage)}
              showUploadList={false}
              customRequest={(option) => fileUploadHandler(option)}
              defaultFileList={fileList}
              fileList={fileList}
              accept=".xlsx"
              maxCount={1}
            >
              {fileList.length < 1 ? (
                <div className="flex flex-col justify-center gap-2 ">
                  <div className="flex justify-center item-center">
                    <p className="text-[#3A3A45] text-[15px] font-normal p-2 rounded-[8px] bg-[#D7D7D9]">
                      Choose file
                    </p>{" "}
                  </div>
                  <p className="text-[#61616A] text-[11px] font-[400] leading-[13px] ">
                    Drag and drop here or Click to upload .xlsx file
                  </p>
                </div>
              ) : (
                fileList.map((fileDetails, index) => {
                  return (
                    <div key={index}>
                      <div className="flex justify-center flex-col items-center gap-[2px] w-full">
                        <Image
                          src={ImageUpload}
                          width={39}
                          height={39}
                          alt=""
                        />
                        <div className="leading-[19.2px] flex flex-col justify-center items-center gap-1">
                          <p className="text-[#3A3A45] text-[14px]  font-normal ">
                            {fileDetails?.name}{" "}
                          </p>
                          <p className="text-[#3A3A45] text-[14px]  font-normal ">
                            {formatSize(fileDetails?.originFileObj?.size)}
                          </p>
                        </div>
                      </div>

                      <div className=" flex gap-2 justify-center items-center w-[30%] mx-auto">
                        <div className="w-[100%]">
                          <Progress percent={progress} showInfo={false} />
                        </div>

                        <Image
                          src={cancelIcon}
                          alt=""
                          className="!cursor-pointer"
                          preview={false}
                          width={15}
                          height={15}
                          onClick={() =>
                            removeUploadedImagesHandler(fileDetails?.uid)
                          }
                        />
                      </div>
                    </div>
                  );
                })
              )}
            </Dragger>
          </div>
          <div className="w-full justify-center flex mt-[20px] items-center ">
            <Button
              className={`px-5  !w-[50%] !mx-auto rounded-[8px] ${
                uploadedFile
                  ? "bg-[#001529] text-white "
                  : "bg-[#f2f2f2] text-[#001529] "
              }   focus:outline-none`}
              onClick={() => importPrice()}
              disabled={!uploadedFile}
            >
              Upload
            </Button>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default DashBoard;
