import { Button, Form, Image, Input, Select, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import adminServices from "../../services/httpServices";
import TextArea from "antd/es/input/TextArea";
import { UploadOutlined } from "@ant-design/icons";
import { currentDateTime } from "../../helpers/helpers";
import NotificationService from "../../NotificationService";

const EditBoard = ({ id ,setIsEditModalOpen}) => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isloading, setIsloading] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [fileList, setFileList] = useState([]);
  const [selectCate, setSelectCate] = useState([]);

  const [uploadedimage, setUploadedImage] = useState(null);

  const fetch = async () => {
    setIsloading(true);
    try {
      if (id) {
        const res = await adminServices.BoardById(id);
    // console.log(res);
        if (res?.data) {
          form.setFieldsValue({
            title: res?.data?.title,
            name: res?.data?.name,
            description: res?.data?.description,
          });
          setImagePath(res?.data?.imgUrl);
          setIsloading(false);
        }
      }
      return;
    } catch (error) {
 NotificationService.show(error.message, "error");
      setIsloading(false);
  // console.log(error);
    }
  };


  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleImageUpload = (info) => {
// console.log(info);
    if (info.file.status === "done") {
      const file = info.file.originFileObj;
      setFileList(info.fileList);
  // console.log(file);
      setUploadedImage(file);
    }
  };
  const imageUpload = async () => {
    if (uploadedimage) {
      setIsloading(true);
      try {
        let data = new FormData();
        data.append("File", uploadedimage);
        const res = await adminServices.fileUpload(data);
        // console.log(res);
        setImagePath(res.data?.path);
        setIsloading(false);
      } catch (error) {
 NotificationService.show(error.message, "error");
    // console.log(error);
        setIsloading(false);
      }
    }
    return;
  };
  const editHandler = async() =>{
    delete values.photo55
    setIsloading(true)
    try {
       await adminServices.EditBoardById(parseInt(id),{
        ...values,
        imgUrl : imagePath,
        dateUpdated : currentDateTime
       });
        setIsloading(false)
        setIsEditModalOpen(false)
    } catch (error) {
 NotificationService.show(error.message, "error");
        setIsloading(false)
        // console.log(error)
    }
  }

useEffect(()=>{
    fetch()
   
},[id])

useEffect(() => {
    imageUpload();
  }, [uploadedimage]);
  return (
    <div>
         <Spin
        spinning={isloading}
        delay={500}
        size="large"
        tip={"Fetching Uploaded staff Data...."}
        className=" text-[#9b7c27] font-bold"
      >


   
      <Form
        form={form}
        // initialValues={initialValues}
        className="bg-gray-50 p-8 rounded border w-full"
        layout="vertical"
          onFinish={() =>
            editHandler()
          }
      >
        <div className="flex flex-col gap-3">
          <Form.Item
            name={"title"}
            className="mb-3 flex-1"
            label="Title"
            rules={[{ required: true }]}
          >
            <Input placeholder="title" />
          </Form.Item>
          <Form.Item
            name={"name"}
            className="mb-3 flex-1"
            label="Name"
            rules={[{ required: true }]}
          >
            <Input placeholder="name" />
          </Form.Item>

          <Form.Item
            name={"description"}
            className="mb-3 flex-1"
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea placeholder="description" />
          </Form.Item>
         
          <div className="h-[150px] w-[150px] my-5  ">
            <img
              src={imagePath}
              alt="uploaded Image"
              className="object-fill w-full h-full rounded-[50%]"
            />
          </div>
          <Form.Item
            // rules={[{ required: true, message: "upload Image" }]}
            className="mb-1 flex-1 flex justify-start items-start"
            name="photo55"
            label="Upload Image"
          >
            <Upload
              onChange={handleImageUpload}
              customRequest={dummyRequest}
              maxCount={1} accept=".svg,.png,.jpg,.jpeg"
              // fileList={fileList}
              className=""
            >
              <Button icon={<UploadOutlined />}>Upload Photo</Button>
            </Upload>
          </Form.Item>
        </div>

        <Form.Item rules={[{ required: true }]} className="mb-1 flex-1">
          <Button
            htmlType="submit"
            className="bg-[#001529] text-white  mt-2"
            // onSubmit={}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
      </Spin>
    </div>
  );
};

export default EditBoard;
