import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { antdTheme } from "./theme/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ScrollToTop } from "./components/scrollToTop/scroll";
import './web.config'
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider theme={antdTheme}>
    <BrowserRouter>
     
      <ScrollToTop />

      <App />
      <ToastContainer />
    </BrowserRouter>
  </ConfigProvider>
);
