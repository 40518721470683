import {
  Button,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Spin,
  Table,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { BsFillCloudUploadFill, BsImageFill } from "react-icons/bs";
import { CiWarning } from "react-icons/ci";
import adminServices from "../../services/httpServices";
import TextArea from "antd/es/input/TextArea";
import { DefaultTime, currentDateTime } from "../../helpers/helpers";
import { UploadOutlined } from "@ant-design/icons";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import EditSlider from "../../components/editSliderForm";
import NotificationService from "../../NotificationService";

const SliderPage = () => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [tableData, setTableData] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenForm, setIsModalOpenForm] = useState(false);
  const [deleteID, setdeleteID] = useState(undefined);
  const [ebook, setEbook] = useState(null);
  const [uploadedimage, setUploadedImage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [EditId, setEditId] = useState(undefined);

  const initialValues = {
    headings1: "",
    headings2: "",
    content: "",
    imageUrl: "",
    isactive: true,
    dateCreated: DefaultTime,
  };
  console.log(values);
  console.log(ebook);

  const columns = [
    {
      title: <p className="text-center">S/N</p>,
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (text) => <p className="text-center">{text}</p>,
    },

    {
      title: <p className="text-center">HEADER</p>,
      dataIndex: "headings1",
      key: "headings1",
      render: (text) => <p className="text-center overflow-ellipsis">{text}</p>,
    },
    {
      title: <p className="text-center">SUB HEADER</p>,
      dataIndex: "headings2",
      key: "headings2",
      render: (text) => <p className="text-center overflow-ellipsis">{text}</p>,
    },
    {
      title: <p className="text-center">CONTENT</p>,
      dataIndex: "content",
      key: "content",
      render: (text) => <p className="text-center ">{text}</p>,
    },
    {
      title: <p className="text-center">IMAGE</p>,
      dataIndex: "imageUrl",
      width: 200,
      key: "imageUrl",
      render: (record) => (
        <div className="w-[200px] rounded-xl flex justify-center items-center  ">
          <Image
            src={record}
            alt="slider "
            className="object-cover rounded-xl flex justify-center items-center"
          />
        </div>
      ),
    },

    {
      title: <p className="text-center">ACTION</p>,
      width: 100,
      // dataIndex: "header",
      // key: "header",
      render: (record) => (
        <div className="flex justify-center items-center gap-5">
          <RiDeleteBin6Fill
            className="text-[20px] cursor-pointer"
            onClick={() => actionDeleteModalOpen(record?.id)}
          />
          <FaEdit
            className="text-[20px] cursor-pointer"
            onClick={() => {
              setIsEditModalOpen(true);
              setEditId(record?.id);
            }}
          />
        </div>
      ),
    },
  ];

  const fetch = async () => {
    setIsloading(true);
    try {
      const response = await adminServices.getAllSlider();
  // console.log(response);
  // console.log(response,'rsponse');
      
      if (response.status === 200 || response.status === 204) {
        setIsloading(false);
        const mappedTableData = response?.data
          ?.filter((value) => value.isactive === true)
          .map((value, index) => {
            return { ...value, key: index };
          });
        setTableData(mappedTableData);
      }
    } catch (error) {
      NotificationService.show(error.message, "error");
  // console.log(error);
      setIsloading(false);
      setTableData([]);
    }
  };

  const actionDeleteModalOpen = (id) => {
    setIsModalOpen(true);
    setdeleteID(id);
  };

  const OnCancelHandler = () => {
    setIsModalOpenForm(false);
    setIsloading(false);

    setdeleteID(undefined);
    setEbook(null);
    form.setFieldsValue(initialValues);
  };

  const actionDeleteHandler = async (id) => {
    try {
      const res = await adminServices.deleteSlider(deleteID);
      setIsModalOpen(false);
     
    } catch (error) {
      NotificationService.show(error.message, "error");
      
    }
  };

  const createSliderHandler = async (data) => {
    setIsloading(true);
    try {
      const res = await adminServices.CreateSlider(data);
      if (res.status === 200) {
        OnCancelHandler();
      }
    } catch (error) {
      NotificationService.show(error.message, "error");
      setIsloading(false);
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleImageUpload = (info) => {
// console.log(info);
    if (info.file.status === "done") {
      const file = info.file.originFileObj;
      setFileList(info.fileList);
  // console.log(file);
      setUploadedImage(file);
    }
  };
  const imageUpload = async () => {
    if (uploadedimage) {
      setIsloading(true);
      try {
        let data = new FormData();
        data.append("File", uploadedimage);
        const res = await adminServices.fileUpload(data);
        // console.log(res);
        setImagePath(res.data?.path);
        setIsloading(false);
      } catch (error) {
        NotificationService.show(error.message, "error");
    // console.log(error);
        setIsloading(false);
      }
    }
    return;
  };
  useEffect(() => {
    fetch();
  }, [isModalOpenForm,isModalOpen]);

  useEffect(() => {
    imageUpload();
  }, [uploadedimage]);
  return (
    <div>
      <div className="flex justify-between items-center ">
        <p className="flex justify-center items-center gap-2 text-[30px] font-[500] leading-loose">
          <span>
            <BsImageFill className="" />
          </span>
          Slider
        </p>
        <Button
          type="text"
          className="uppercase font-bold text-[16px] justify-center items-center flex 
           gap-2 bg-[#001529] text-white py-5"
          onClick={() => setIsModalOpenForm(true)}
        >
          <span>
            <BsFillCloudUploadFill className="text-[20px]" />
          </span>
          Upload
        </Button>
      </div>
      <Spin
        spinning={isloading}
        delay={500}
        size="large"
        tip={"Fetching Table Data...."}
        className=" text-[#9b7c27] font-bold"
      >
        <Table
          dataSource={tableData}
          columns={columns}
          // size="small"
          className=" w-full "
          scroll={{ x: 400 }}
        />
      </Spin>
      <Modal
        open={isModalOpen}
        centered={true}
        okText="save"
        cancelText="Make Changes"
        onOk={() => actionDeleteHandler()}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <div
            key={2}
            className=" w-[80%] mx-auto flex justify-center items-center"
          >
            <Button
              key="back"
              htmlType="submit"
              className="w-full bg-[] text-[#001529] font-bold"
              onClick={OnCancelHandler}
            >
              Make Changes
            </Button>
            <Button
              key="submit"
              className="w-full bg-[red] text-[white] font-bold"
              onClick={() => actionDeleteHandler()}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <div className=" w-[80%] mx-auto flex flex-col justify-center items-center text-center gap-5">
          <CiWarning className="text-[40px] text-[#9b7c27]" />
          <p className="text-[18px] leading-[21px] flex flex-col font-bold gap-1 ">
            This Action is Irreversible
            <span className="text-[14px] font-normal">
              Are You Sure You Want To Delete
            </span>
          </p>
        </div>
      </Modal>

      <Modal
        open={isEditModalOpen}
        centered={true}
        okText="save"
        cancelText="Make Changes"
        onOk={actionDeleteHandler}
        onCancel={() => {
          setIsEditModalOpen(false);
          setEditId(undefined);
        }}
        footer={null}
      >
        <EditSlider id={EditId} />
      </Modal>

      <Modal
        open={isModalOpenForm}
        centered={true}
        onOk={() => actionDeleteHandler}
        onCancel={() => setIsModalOpenForm(false)}
        footer={[
          <div key={2} className="  flex justify-center items-center"></div>,
        ]}
        className="lg:!w-[40%] !w-[90%] !h-[50%]"
      >
        <div className="flex flex-col justify-center items-center text-center mt-8 !w-full">
          <div className="w-full">
            <Spin
              tip="Creating Slider...."
              spinning={isloading}
              className="text-[#9b7c27] "
            >
              <Form
                form={form}
                initialValues={initialValues}
                className="bg-gray-50 p-8 rounded border"
                layout="vertical"
                onFinish={() =>
                  createSliderHandler({
                    ...values,
                    isactive: true,
                    dateCreated: currentDateTime,
                    imageUrl: imagePath,
                  })
                }
              >
                <div className="flex flex-col gap-3">
                  <Form.Item
                    name={"headings1"}
                    className="mb-3 flex-1"
                    label="first Heading"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="heading1" />
                  </Form.Item>
                  <Form.Item
                    name={"headings2"}
                    className="mb-3 flex-1"
                    label="second Heading"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder="heading2" />
                  </Form.Item>

                  <Form.Item
                    name={"content"}
                    className="mb-3 flex-1"
                    label="content"
                    rules={[{ required: true }]}
                  >
                    <TextArea placeholder="content" />
                  </Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "upload Image" }]}
                    className="mb-1 flex-1 flex justify-start items-start"
                    name="photo55"
                    label="Upload Image"
                  >
                    <Upload
                      onChange={handleImageUpload}
                      customRequest={dummyRequest}
                      maxCount={1} accept=".svg,.png,.jpg,.jpeg"
                      // fileList={fileList}
                      className=""
                    >
                      <Button icon={<UploadOutlined />}>Upload Photo</Button>
                    </Upload>
                  </Form.Item>
                </div>

                <Form.Item rules={[{ required: true }]} className="mb-1 flex-1">
                  <Button
                    htmlType="submit"
                    className="bg-[#001529] text-white  mt-2"
                    // onSubmit={}
                  >
                    Create Slider
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SliderPage;
