import apiInstance from "./config";

const adminServices = { };

adminServices.getAllSlider = ()=> apiInstance({
    url: `/Slider`,
    method: "get",
    headers: { "public-request": "true" },
})
adminServices.getSliderById = (id)=> apiInstance({
    url: `/Slider/${id}`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.EditSliderById = (id,data)=> apiInstance({
    url: `/Slider/${id}`,
    method: "put",
    data,
    
      headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.getAllPostData = ()=> apiInstance({
    url: `/Post`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.getAllPostType = ()=> apiInstance({
    url: `/AppData/PostCategory`,
    method: "get",
      headers: {
        'Content-Type': 'application/json',
      },
})


adminServices.getAllBoard = ()=> apiInstance({
    url: `/ManagementTeams`,
    method: "get",
    headers: { "public-request": "true" },
})
adminServices.BoardById = (id)=> apiInstance({
    url: `/ManagementTeams/${id}`,
    method: "get",
      headers: {
        'Content-Type': 'application/json',
      },
})
adminServices.EditBoardById = (id,data)=> apiInstance({
    url: `/ManagementTeams/${id}`,
    method: "put",
    data,
    
      headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.getAllServices = ()=> apiInstance({
    url: `/Services`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.getAllAccountOpening = (formId)=> apiInstance({
    url: `/AccountOpening/GetPersonalInfoByFormTypeId/${formId}`,
    method: "get",
    headers: { "public-request": "true" },
})
adminServices.getPersonalDetailsById = (id)=> apiInstance({
    url: `/AccountOpening/GetPersonalInfoByPersonalDetailId/${id}`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.getCompanyDetailsById = (id)=> apiInstance({
    url: `/AccountOpening/GetAccountResponseByCompanyId/${id}`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.deleteSlider = (id)=> apiInstance({
    url: `/Slider/${id}`,
    method: "delete",
    headers: { "public-request": "true" },
})
adminServices.deletePost = (id)=> apiInstance({
    url: `/Post/${id}`,
    method: "delete",
    headers: { "public-request": "true" },
})
adminServices.mgtDelete = (id)=> apiInstance({
    url: `/ManagementTeams/${id}`,
    method: "delete",
    headers: { "public-request": "true" },
})
adminServices.GetAllStocks = ()=> apiInstance({
    url: `/v1/StockMarket/list`,
    method: "get",
    headers: { "public-request": "true" },
})
adminServices.getStaffCategory = ()=> apiInstance({
    url: `/AppData/TeamCategory`,
    method: "get",
    headers: { "public-request": "true" },
})
adminServices.CreateStock = (data)=> apiInstance({
    url: `/vi/StockMarket`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.CreateSlider = (data)=> apiInstance({
    url: `/Slider`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.createNewBoardMember = (data)=> apiInstance({
    url: `/ManagementTeams`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.createServices = (data)=> apiInstance({
    url: `/Services`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.fileUpload = (data)=> apiInstance({
    url: `/AppData/FileUpload`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'multipart/form-data',
      },
})
adminServices.authenticateUser = (data)=> apiInstance({
    url: `/User/AuthenticateUser`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})
adminServices.CreateUser = (data)=> apiInstance({
    url: `/User/CreateUser`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.Post = (data)=> apiInstance({
    url: `/Post`,
    method: "post",
    data,
    headers: {
        'Content-Type': 'application/json',
      },
})
adminServices.MissionVision = ()=> apiInstance({
    url: `/MissionVision`,
    method: "get",
    headers: { "public-request": "true" },
})

adminServices.EditVision = (id,data)=> apiInstance({
    url: `/MissionVision/${id}`,
    method: "put",
    data,
    
      headers: {
        'Content-Type': 'application/json',
      },
})

adminServices.importPrice = (data) =>
    apiInstance({
      url: "/v1/StockMarket/ImportStockMarket",
      method: "post",
      data,
      headers: { "public-request": "true" },
    });


export default adminServices