import React, { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DashboardOutlined,
  SettingOutlined,
  HomeOutlined,
  // BsImageFill,
  UserAddOutlined,
  InsertRowAboveOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import { BsImageFill } from "react-icons/bs";
import { Layout, Menu, Button, Tooltip } from "antd";
import { GiHistogram } from "react-icons/gi";
import { PiIdentificationCardThin } from "react-icons/pi";
import logo from "../../Assets/Heritage_logo.png";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";
import { FaPersonChalkboard } from "react-icons/fa6";
import { GrServices } from "react-icons/gr";
import { MdBookOnline } from "react-icons/md";
import { RiContactsBookFill } from "react-icons/ri";

const { Header, Sider, Content } = Layout;

const Homepage2 = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    getItem("DashBoard", "/", <DashboardOutlined />),
    getItem("Administrator", "/admin2", <SettingOutlined />, [
      getItem("Create Admin", "/admin", <UserAddOutlined />),
    ]),
    getItem("Homepage", "/homepage", <HomeOutlined />, [
      getItem("Slider", "/slider", <BsImageFill />),
      getItem("Post", "/Post", <InsertRowAboveOutlined />),
      // getItem("Business-news", "/business-news", <InsertRowAboveOutlined />),
    ]),
    getItem("Registration", "/IndividualAccount2", <MdBookOnline />, [
      getItem("Individual Account", "/IndividualAccount", <MdBookOnline />),
      getItem("Minor Account", "/minor_Account", <MdBookOnline />),
      getItem("Corporate Account ", "/corporate_Account", <MdBookOnline />),
      getItem("Estate Account", "/estate_Account", <MdBookOnline />),
      getItem("Joint Account", "/joint_Account", <MdBookOnline />),
    ]),

 
    getItem("Staffs ", "/boards", <FaPersonChalkboard />),
   
    getItem("Vision & Mission ", "/vision&Mission", <GrServices />),
  ];
  const currrentWidth = window.innerWidth;
  // console.log(items);

  const handleLogOut = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("user");
    navigate("/SignIn");
  };
  return (
    <Layout hasSider className="">
      <Sider
        trigger={null}
        collapsible
        collapsed={currrentWidth < 1024 ? true : collapsed}
        className="!sticky  top-0 left-0 bottom-0  h-screen  "
        width="250"
      >
        <div className="flex flex-col justify-between  lg:h-full h-[95vh]">
          <div className="">
            {collapsed ? (
              <div className="px-2 my-8  lg:block hidden">
                <img src={logo} alt="" />
              </div>
            ) : (
              <div className="px-10 py-5 lg:block hidden">
                <img src={logo} alt="" />
              </div>
            )}
            <div className="px-2 my-8 lg:hidden block">
              <img src={logo} alt="" />
            </div>
            <Menu
              theme="dark"
              defaultSelectedKeys={[location.pathname]}
              onClick={({ key }) => navigate(key)}
              mode="inline"
              items={items}
              className=" h-full"
            />
          </div>
          <Tooltip title={"Log Out"}>
            <div className="flex justify-center items-center mb-10">
              <FaPowerOff
                className="text-[25px] cursor-pointer text-[white]  lg:hidden block"
                onClick={() => handleLogOut()}
              />
            </div>
          </Tooltip>
        </div>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            // background: colorBgContainer,
          }}
          className="!sticky hidden lg:block top-0 !z-[999]"
        >
          <div className="flex lg:justify-between justify-end items-center h-full w-[95%] ">
            <Tooltip title={"Close/Open SideBar"}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
                className="hidden lg:block"
              />
            </Tooltip>

            <Tooltip title={"Log Out"}>
              <FaPowerOff
                className="text-[20px] cursor-pointer text-[#001529] hidden lg:block"
                onClick={() => handleLogOut()}
              />
            </Tooltip>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 5,
            // overflow: 'initial',
            // minHeight: 280,
            background: "whitesmoke",

          }}
          //   className="!h-full"
          data-aos="fade-right"
          data-aos-easing="linear"
          // data-aos-duration="5 00"
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default Homepage2;
