import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
const QuoteComponent = ({ quotedetails }) => {
  return (
    <div>
      <div className=" flex flex-col justify-center items-center bg-[#001529] rounded-lg">
        <p className="text-[24px] text-[#9b7c27] py-5  font-bold ">
          Our Vision
        </p>
        <div className="flex justify-center items-center   text-[18px] font-[600] text-[#fcfcfc] text-center p-5 gap-5 leading-loose">
          <span className="text-[30px]">
            <FaQuoteLeft />
          </span>
          <p className="">{quotedetails?.vission}</p>

          <span className="text-[30px]">
            <FaQuoteRight />
          </span>
        </div>
      </div>

      <div className=" flex flex-col justify-center items-center bg-[#001529] rounded-lg">
        <p className="text-[24px] text-[#9b7c27] py-5  font-bold ">
          Our Mission
        </p>
        <div className="flex justify-center items-center   text-[18px] font-[600] text-[#fcfcfc] text-center p-5 gap-5 leading-loose">
          <span className="text-[30px]">
            <FaQuoteLeft />
          </span>
          <p className="">{quotedetails?.mission}</p>

          <span className="text-[30px]">
            <FaQuoteRight />
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuoteComponent;
