import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { RiAdminLine } from "react-icons/ri";
import adminServices from "../../services/httpServices";
import NotificationService from "../../NotificationService";
import { useLocation, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const location = useLocation();
  const navigate = useNavigate();
console.log(location.search);
  const [isLoading, setisLoading] = useState(false);
  // const [password, setPassword] = useState("");

  const handleSubmit = async (data) => {
    try {
      setisLoading(true);
      const res = await adminServices.authenticateUser(data);
      if (res?.data?.message === "Logged in successfully") {
        localStorage.setItem("userToken", res?.data?.data?.token);
        localStorage.setItem("user", JSON.stringify(res?.data?.data?.userId));
        NotificationService.show(res?.data?.message, "success");

        navigate('/');
      } else NotificationService.show(res?.data?.message, "error");
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
      NotificationService.show(error.message, "error");
    }
  };
  return (
    <div className="flex flex-col justify-center items-center h-screen gap-10 border rounded-[20px] mx-auto  bg-[#001529]">
      <RiAdminLine className=" text-[100px] fill-white" />
      <div className="flex border lg:w-[60%] w-[90%] h-[50vh] mx-auto justify-center items-center">
        <div className="lg:flex justify-center items-center text-[24px] h-full bg-[#9b7c27] w-full hidden">
          <h3 className="text-white font-bold">Admin Login.</h3>
        </div>
        <div className="account-form  w-full h-full bg-white  flex justify-center items-center ">
          <Form
            form={form}
            // initialValues={initialValues}
            className=" p-8 w-full rounded  "
            layout="vertical"
            onFinish={() => handleSubmit(values)}
          >
            <p className="text-[18px] uppercase">
              <strong>Sign in</strong> <i>to your account</i>
            </p>
            <Form.Item
              name={"email"}
              className="mt-5 flex-1"
              label="Enter your email address"
              rules={[{ required: true }]}
            >
              <Input
                type="email"
                placeholder="Email"
                required
                className="!w-full !h-[40px] px-2 outline-8 border"
              />
            </Form.Item>
            <Form.Item
              name={"password"}
              className="mb-3 flex-1"
              label="Enter your password address"
              rules={[{ required: true }]}
            >
              <Input.Password
                type="password"
                placeholder="Password"
                required
                className="!w-full !h-[40px] px-2 outline-8 border"
              />
            </Form.Item>

            <div className="flex justify-between items-center gap-5 mt-5">
              <Button
                htmlType="submit"
                className="border bg-[#1b1b1b] text-[white] px-10 h-[40px] rounded-[30px]  !w-full"
                disabled={
                  values?.email?.length < 1 ||
                  values?.password?.length < 1 ||
                  !/[@]/.test(values?.email)
                }
                loading={isLoading}
              >
                Sign In
              </Button>
              {/* <span className="forgot-password">Forgot password?</span> */}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
