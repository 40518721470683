import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getSession } from "../services/config";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  if (
    !localStorage.getItem("userToken") &&
    !getSession(localStorage.getItem("userToken"))
  ) {
    return (
      <Navigate
        to={`/signIn?redirect=${location.pathname}${location.search}`}
        state={{ from: location }}
      />
    );
  }

  return (
    <>
      {children}
      <Outlet />
    </>
  );
};

export default PrivateRoute;
