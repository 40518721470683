import { useEffect, useRef, useState } from "react";
import adminServices from "../../services/httpServices";
import { Button, Spin } from "antd";
import UploadPreview from "../uploadPreviewCard";
import NotificationService from "../../NotificationService";
import { UploadOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";

const CompanyDetails = ({ id, type }) => {
  const [isloading, setIsloading] = useState(false);
  const [companyDetails, setcompanyDetails] = useState({});
  const componentRef = useRef();
  const fetch = async () => {
    setIsloading(true);
    try {
      const res = await adminServices.getCompanyDetailsById(id);
  // console.log(res?.data[0]?.company);
      setcompanyDetails(res?.data[0]?.company);
      setIsloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
      setIsloading(false);
  // console.log(error);
    }
  };

  useEffect(() => {
    fetch();
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="flex justify-center items-center !z-[999]">
      {isloading ? (
        <Spin spinning={isloading} fullscreen />
      ) : (
        <div className="flex flex-col justify-start items-start w-full">
          <div className="flex justify-end items-end  w-full mt-5 mb-2 py-5">
            <Button icon={<UploadOutlined />} onClick={handlePrint}>
              Export
            </Button>
          </div>
          <div
            className=" w-[100%] mx-auto flex flex-col justify-center items-center text-center gap-5"
            ref={componentRef}
          >
            <div className="flex flex-col justify-center items-center gap-3 py-10 sticky top-0 border w-full bg-[#001529] !z-[1000] ">
              <div className=" capitalize text-sm font-bold flex-col flex justify-center items-center gap-3 w-full text-center  text-[#9b7c27]">
                <div>
                  <p className="text-lg">{companyDetails?.companyVM?.name} </p>

                  <p>{companyDetails?.companyVM?.address}</p>
                </div>

                <div className=" w-full flex justify-between items-center px-5">
                  <p>
                    {type === "estate"
                      ? "Mother's maiden name :"
                      : type === "joint"
                      ? "Contact"
                      : "REC No :"}{" "}
                    {companyDetails?.companyVM?.registrationNumber}
                  </p>
                  {type === "joint" ? null : (
                    <p>
                      {type === "estate"
                        ? "Date of birth (Deceased) :"
                        : "Date Of Registration :"}
                      {companyDetails?.companyVM?.dateOfRegistration?.slice(
                        0,
                        10
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {companyDetails?.directorVMs?.map((companyDetails, index) => (
              <div className="w-full" key={index}>
                <div className="w-full">
                  <p className="text-[#001529] text-lg font-bold text-start w-full py-4 flex justify-start items-start flex-col">
                    <span className="border w-full bg-[#001529] text-lg font-bold py-2 mb-2 text-[#9b7c27]  text-center">
                      {type === "estate"
                        ? "Armor"
                        : type === "joint"
                        ? "Party"
                        : "Director"}{" "}
                      {index + 1}
                    </span>
                    Personal Details
                  </p>
                  <div className="w-[90%] mx-auto flex flex-col justify-start items-start gap-1 pr-1  ">
                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">
                        Full Name:{" "}
                      </span>
                      <span className="text-black  text-[15px] w-[80%] capitalize ">
                        {companyDetails?.personalDetailsVM?.surname}{" "}
                        {companyDetails?.personalDetailsVM?.firstName}{" "}
                        {companyDetails?.personalDetailsVM?.middleName}
                      </span>
                    </p>

                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">
                        Date Of Birth :
                      </span>
                      <span className="text-black capitalize text-[15px] w-[80%]  ">
                        {companyDetails?.personalDetailsVM?.dateofBirth?.slice(
                          0,
                          10
                        )}
                      </span>
                    </p>

                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">Gender : </span>
                      <span className="text-black capitalize text-[15px] w-[80%]  ">
                        {companyDetails?.personalDetailsVM?.sex}
                      </span>
                    </p>

                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">Address : </span>
                      <span className="text-black capitalize text-[15px] w-[80%]  ">
                        {companyDetails?.personalDetailsVM?.address}
                      </span>
                    </p>

                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">Country : </span>
                      <span className="text-black capitalize text-[15px] w-[80%]  ">
                        {companyDetails?.personalDetailsVM?.country}
                      </span>
                    </p>
                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">
                        State of Origin :{" "}
                      </span>
                      <span className="text-black  text-[15px] w-[80%] capitalize ">
                        {companyDetails?.personalDetailsVM?.state}{" "}
                      </span>
                    </p>
                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">City : </span>
                      <span className="text-black  text-[15px] w-[80%] capitalize ">
                        {companyDetails?.personalDetailsVM?.city}{" "}
                      </span>
                    </p>

                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">Number : </span>
                      <span className="text-black capitalize text-[15px] w-[80%]  ">
                        {companyDetails?.personalDetailsVM?.phoneNumber}
                      </span>
                    </p>

                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">Email: </span>
                      <span className="text-black  text-[15px] w-[80%]  ">
                        {companyDetails?.personalDetailsVM?.email?.toLowerCase()}
                      </span>
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-5 ">
                    <UploadPreview
                      imageUrl={companyDetails?.personalDetailsVM?.imgUrl}
                      name={"passport Photograph"}
                    />

                    <UploadPreview
                      imageUrl={companyDetails?.personalDetailsVM?.signatureUrl}
                      name={"Customer-signature Image"}
                    />
                  </div>
                </div>

                <div className="w-full">
                  <p className="text-[#001529] text-lg font-bold text-start w-full py-4">
                    Next Of Kin's Details
                  </p>
                  <div className="w-[90%] mx-auto flex flex-col justify-start items-start gap-1 pr-1  ">
                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">Name: </span>
                      <span className="text-black  text-[15px] w-[80%] capitalize ">
                        {companyDetails?.nextOfKin?.name}
                      </span>
                    </p>
                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">
                        Relationship:{" "}
                      </span>
                      <span className="text-black  text-[15px] w-[80%] capitalize ">
                        {companyDetails?.nextOfKin?.relationship}{" "}
                      </span>
                    </p>

                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">contact : </span>
                      <span className="text-black  text-[15px] w-[80%] capitalize ">
                        {companyDetails?.nextOfKin?.phoneNumber}{" "}
                      </span>
                    </p>
                    <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                      <span className="md:w-[30%] lg:w-[20%] ">Address: </span>
                      <span className="text-black  text-[15px] w-[80%]  ">
                        {companyDetails?.nextOfKin?.address}
                      </span>
                    </p>
                  </div>
                </div>
                {companyDetails?.bankDetailsVM === null ? (
                  null
                ) : (
                  <div className="w-full">
                   
                    {/* <p className="text-[#001529] text-lg font-bold text-start w-full py-4 flex justify-start items-start flex-col"> */}
                    <p className="border w-full bg-[#001529] text-lg font-bold py-2 mt-4 mb-2 text-[#9b7c27]  text-center">
                     Bank Details
                    </p>
                   
                  {/* </p> */}
                    <div className="w-[90%] mx-auto flex flex-col justify-start items-start gap-1 pr-1  ">
                      <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                        <span className="md:w-[30%] lg:w-[20%] ">
                          Bank Name:{" "}
                        </span>
                        <span className="text-black  text-[15px] w-[80%] capitalize ">
                          {companyDetails?.bankDetailsVM?.bankName}
                        </span>
                      </p>
                      <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                        <span className="md:w-[30%] lg:w-[20%] ">
                          Account Name:{" "}
                        </span>
                        <span className="text-black  text-[15px] w-[80%] capitalize ">
                          {companyDetails?.bankDetailsVM?.accountName}{" "}
                        </span>
                      </p>
                      <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                        <span className="md:w-[30%] lg:w-[20%] ">
                          Account Number :{" "}
                        </span>
                        <span className="text-black  text-[15px] w-[80%] capitalize ">
                          {companyDetails?.bankDetailsVM?.accountNumber}{" "}
                        </span>
                      </p>
                      <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                        <span className="md:w-[30%] lg:w-[20%] ">BVN : </span>
                        <span className="text-black  text-[15px] w-[80%] capitalize ">
                          {companyDetails?.bankDetailsVM?.bvn}{" "}
                        </span>
                      </p>
                      <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                        <span className="md:w-[30%] lg:w-[20%] ">
                          Branch Address:{" "}
                        </span>
                        <span className="text-black  text-[15px] w-[80%]  ">
                          {companyDetails?.bankDetailsVM?.branchAddress}
                        </span>
                      </p>
                      <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                        <span className="md:w-[30%] lg:w-[20%] ">
                          Bank Short Code :{" "}
                        </span>
                        <span className="text-black  text-[15px] w-[80%] capitalize ">
                          {companyDetails?.bankDetailsVM?.bankShortCode}{" "}
                        </span>
                      </p>
                      <p className="text-justify text-[14px] text-[grey]  border-b flex justify-between items-center w-full py-1">
                        <span className="md:w-[30%] lg:w-[20%] ">Chn : </span>
                        <span className="text-black  text-[15px] w-[80%] capitalize ">
                          {companyDetails?.bankDetailsVM?.chn}{" "}
                        </span>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}

            {type === "estate" ? (
              <div className="grid grid-cols-2 gap-5 ">
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.companyResolution}
                  name={"Will or Letter of Administration "}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.namingResolution}
                  name={"Death Certificate"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.signatoryPassport}
                  name={"Newspaper Publication"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.utilityBill}
                  name={"Utility Bill"}
                />
              </div>
            ) : type === "joint" ? (
              <div className="grid grid-cols-2 gap-5 ">
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.companyResolution}
                  name={
                    "Authority to create an account with HCML signed by both parties "
                  }
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.utilityBill}
                  name={"Utility Bill"}
                />
              </div>
            ) : (
              <div className="grid grid-cols-3 gap-5 ">
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.boardSignature1}
                  name={"Board Signature 1"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.boardSignature2}
                  name={"board Signature 2"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.boardSignature3}
                  name={"board Signature 3"}
                />
                <UploadPreview
                  imageUrl={
                    companyDetails?.companyVM?.certificateOfIncorporation
                  }
                  name={"certificate Of Incorporation"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.caC02}
                  name={"caC02"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.caC07}
                  name={"caC07"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.idCardOfSignatory}
                  name={"idCard Of Signatory"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.idCardOfSignatory2}
                  name={"idCard Of Signatory 2"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.companyResolution}
                  name={"company Resolution"}
                />

                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.namingResolution}
                  name={"naming Resolution"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.signatoryPassport}
                  name={"signatory Passport"}
                />
                <UploadPreview
                  imageUrl={companyDetails?.companyVM?.signator2Passport}
                  name={"signator 2 Passport"}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyDetails;
