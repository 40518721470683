import { useEffect, useRef, useState } from "react";
import adminServices from "../../services/httpServices";
import { Button, Spin } from "antd";
import UploadPreview from "../uploadPreviewCard";
import { useLocation } from "react-router-dom";
import NotificationService from "../../NotificationService";
import { useReactToPrint } from "react-to-print";
import { UploadOutlined } from "@ant-design/icons";

const Details = ({ id }) => {
  const [isloading, setIsloading] = useState(false);
  const [personalDetails, setPersonalDetails] = useState({});
  const {pathname} = useLocation()
  const componentRef = useRef();

  const fetch = async () => {
    setIsloading(true);
    try {
      const res = await adminServices.getPersonalDetailsById(id);
      //   console.log(res);
      setPersonalDetails(res?.data);
      setIsloading(false);
    } catch (error) {
 NotificationService.show(error.message, "error");
      setIsloading(false);
  // console.log(error);
    }
  };

  

  useEffect(() => {
    fetch();
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Spin
      spinning={isloading}
      delay={500}
      size="large"
      tip={"Fetching user Data...."}
      className=" text-[#9b7c27] font-bold "
    >
      <div className="flex justify-end items-end  w-full mt-5 mb-2 py-5">
      <Button icon={<UploadOutlined />} onClick={handlePrint}>Export</Button>
      </div>
   
      <div className=" w-[100%] mx-auto flex flex-col justify-center items-center text-center gap-5 !z-[999]" ref={componentRef}>
        <div className="flex flex-col justify-center items-center gap-3 py-10 sticky top-0 border w-full bg-[#001529] z-[1000] ">
          <div className=" w-[200px] h-[200px] ">
            <img
              src={personalDetails?.personalDetailsVM?.imgUrl}
              alt=""
              className=" w-full h-full !rounded-[100%] object-cover border-2 bg-white"
            />
          </div>
          <p className=" capitalize text-sm font-bold justify-center items-center gap-10 w-full text-center  text-[#9b7c27]">
            {personalDetails?.personalDetailsVM?.surname}{" "}
            {personalDetails?.personalDetailsVM?.firstName}
          </p>
        </div>

        <div className="w-full">
          <div className="w-full">
            <p className="text-[#001529] text-lg font-bold text-start w-full py-4">
              Personal Details
            </p>
            <div className="w-[90%] mx-auto flex flex-col justify-start items-start gap-1 pr-1  ">
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Full Name: </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.personalDetailsVM?.surname}{" "}
                  {personalDetails?.personalDetailsVM?.firstName}{" "}
                  {personalDetails?.personalDetailsVM?.middleName}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Maiden's Name: </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.personalDetailsVM?.maidenName}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">
                  State of Origin :{" "}
                </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.personalDetailsVM?.state}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">City : </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.personalDetailsVM?.city}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Email: </span>
                <span className="text-black  text-[15px] w-[80%]  ">
                  {personalDetails?.personalDetailsVM?.email?.toLowerCase()}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">ID Type : </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.personalDetailsVM?.idCardType}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">ID Number : </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.personalDetailsVM?.idCardNumber}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">
                  ID Expiry Date :{" "}
                </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.personalDetailsVM?.idCardExpiryDate?.slice(
                    0,
                    10
                  )}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Date Of Birth :</span>
                <span className="text-black capitalize text-[15px] w-[80%]  ">
                  {personalDetails?.personalDetailsVM?.dateofBirth?.slice(
                    0,
                    10
                  )}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Number : </span>
                <span className="text-black capitalize text-[15px] w-[80%]  ">
                  {personalDetails?.personalDetailsVM?.phoneNumber}
                </span>
              </p>

              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Gender : </span>
                <span className="text-black capitalize text-[15px] w-[80%]  ">
                  {personalDetails?.personalDetailsVM?.sex}
                </span>
              </p>

              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Country : </span>
                <span className="text-black capitalize text-[15px] w-[80%]  ">
                  {personalDetails?.personalDetailsVM?.country}
                </span>
              </p>

              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Address : </span>
                <span className="text-black capitalize text-[15px] w-[80%]  ">
                  {personalDetails?.personalDetailsVM?.address}
                </span>
              </p>
            </div>
          </div>

          <div className="w-full">
            <p className="text-[#001529] text-lg font-bold text-start w-full py-4">
             {pathname === '/minor_Account' ? 'Guardian Details' : "Next Of Kin's Details"} 
            </p>
            <div className="w-[90%] mx-auto flex flex-col justify-start items-start gap-1 pr-1  ">
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Name: </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.nextOfKinVM?.name || personalDetails?.guardianVM?.name}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Relationship: </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.nextOfKinVM?.relationship || personalDetails?.guardianVM?.relationship}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Occupation : </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.nextOfKinVM?.occupation || personalDetails?.guardianVM?.occupation}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">contact : </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.nextOfKinVM?.phoneNumber || personalDetails?.guardianVM?.phoneNumber }{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Address: </span>
                <span className="text-black  text-[15px] w-[80%]  ">
                  {personalDetails?.nextOfKinVM?.address || personalDetails?.guardianVM?.address}
                </span>
              </p>
            </div>
          </div>

          <div className="w-full">
            <p className="text-[#001529] text-lg font-bold text-start w-full py-4">
              Bank Details
            </p>
            <div className="w-[90%] mx-auto flex flex-col justify-start items-start gap-1 pr-1  ">
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Bank Name: </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.bankDetailsVM?.bankName}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Account Name: </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.bankDetailsVM?.accountName}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">
                  Account Number :{" "}
                </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.bankDetailsVM?.accountNumber}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">BVN : </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.bankDetailsVM?.bvn}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Branch Address: </span>
                <span className="text-black  text-[15px] w-[80%]  ">
                  {personalDetails?.bankDetailsVM?.branchAddress}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">
                  Bank Short Code :{" "}
                </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.bankDetailsVM?.bankShortCode}{" "}
                </span>
              </p>
              <p className="text-start lg:text-justify text-[14px] text-[grey]  border-b flex justify-between items-start lg:items-center w-full py-1 flex-col lg:flex-row">
                <span className="md:w-[30%] lg:w-[20%] ">Chn : </span>
                <span className="text-black  text-[15px] lg:w-[80%] w-full  capitalize ">
                  {personalDetails?.bankDetailsVM?.chn}{" "}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 ">
          <UploadPreview
            imageUrl={personalDetails?.personalDetailsVM?.idCardUrl}
            name={'ID Card Image'}
          />
           <UploadPreview
            imageUrl={personalDetails?.personalDetailsVM?.imgUrl}
            name={'passport Photograph'}
          />
           <UploadPreview
            imageUrl={personalDetails?.personalDetailsVM?.utilityBillUrl}
            name={'Utility Bill Image'}
          />
           <UploadPreview
            imageUrl={personalDetails?.personalDetailsVM?.signatureUrl}
            name={'Customer-signature Image'}        
          />
           <UploadPreview
            imageUrl={personalDetails?.personalDetailsVM?.birthCertificateUrl}
            name={'Birth-Certificate Image'}        
          />
        </div>
      </div>
     
      
    </Spin>
  );
};

export default Details;
