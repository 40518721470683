import { Image } from "antd";
import React, { useState } from "react";
import DetailsModal from "../detailsModal/DetailsModal";

const BoardCard = ({ boardDetails }) => {
  const [isModalOpen, setIsmodalOpen] = useState(false);

  return (
    <div
      className="flex justify-start items-center flex-col md:flex-row gap-2  py-2 border-b bg-[#001529]   shadow lg  cursor-pointer  rounded-[16px] w-full "
      onClick={() => setIsmodalOpen(!isModalOpen)}
    >
      <div className="flex flex-col justify-center items-center gap-3 w-[50%] lg:border-r ">
        <div className=" w-[150px] h-[150px] ">
          <img
            src={boardDetails?.imgUrl}
            alt=""
            className=" w-full h-full !rounded-[100%] object-cover border-2 bg-white"
          />
        </div>
        <p className=" capitalize text-sm font-bold justify-center items-center gap-10 w-full text-center  text-[#9b7c27]">
          {boardDetails?.surname} {boardDetails?.firstName}
        </p>
      </div>

      <div className="w-full flex flex-col justify-start items-start gap-1 px-5 lg:px-1  text-[#9b7c27]">
        <p className="lg:text-justify text-[14px] text-[grey]  border-b flex lg:flex-row flex-col w-full py-1">
          <span className="md:w-[30%] lg:w-[25%] ">Email: </span>
          <span className="text-white  text-[15px] w-[80%]  ">
            {boardDetails?.email?.toLowerCase()}
          </span>
        </p>
        <p className="lg:text-justify text-[14px] text-[grey]  border-b flex lg:flex-row flex-col w-full py-1">
          <span className="md:w-[30%] lg:w-[25%] ">D . O .B</span>
          <span className="text-white capitalize text-[15px] w-[80%]  ">
            {boardDetails?.dateofBirth?.slice(0, 10)}
          </span>
        </p>
        <p className="lg:text-justify text-[14px] text-[grey]  border-b flex lg:flex-row flex-col w-full py-1">
          <span className="md:w-[30%] lg:w-[25%] ">Number : </span>
          <span className="text-white capitalize text-[15px] w-[80%]  ">
            {boardDetails?.phoneNumber}
          </span>
        </p>

        <p className="lg:text-justify text-[14px] text-[grey]  border-b flex lg:flex-row flex-col w-full py-1">
          <span className="md:w-[30%] lg:w-[25%] ">Gender : </span>
          <span className="text-white capitalize text-[15px] w-[80%]  ">
            {boardDetails?.sex}
          </span>
        </p>

        <p className="lg:text-justify text-[14px] text-[grey]  border-b flex lg:flex-row flex-col w-full py-1">
          <span className="md:w-[30%] lg:w-[25%] ">Country : </span>
          <span className="text-white capitalize text-[15px] w-[80%]  ">
            {boardDetails?.country}
          </span>
        </p>

        <p className="lg:text-justify text-[14px] text-[grey]  border-b flex lg:flex-row flex-col w-full py-1">
          <span className="md:w-[30%] lg:w-[25%] ">Address : </span>
          <span className="text-white capitalize text-[15px] w-[80%]  ">
            {boardDetails?.address}
          </span>
        </p>
      </div>

      <DetailsModal
        id={boardDetails?.id}
        setIsmodalOpen={setIsmodalOpen}
        isModalOpen={isModalOpen}
      />
    </div>
  );
};

export default BoardCard;
