import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import adminServices from "../../services/httpServices";
import { currentDateTime } from "../../helpers/helpers";
import NotificationService from "../../NotificationService";

const AdminPage = () => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isloading, setisloading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = async () => {
    setisloading(true);
    delete values?.testPassword;
    try {
      const res = await adminServices.CreateUser({
        ...values,
        isEmailVerify: true,
        imageUrl: "",
        createdDate: currentDateTime,
        lastLoginDate: currentDateTime,
        isActive: true,
        dateupdated: currentDateTime,
      });
      setisloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
      setisloading(false);
    }
  };

  return (
    <div className="flex justify-center items-center  h-full ">
      <Form
        form={form}
        // initialValues={initialValues}
        className=" p-8 rounded   flex flex-col justify-center items-center  bg-white w-full lg:w-[50%] mx-auto   shadow"
        layout="vertical"
        onFinish={() => {
          handleSubmit();
        }}
      >
        <div className="flex justify-between items-center flex-col lg:flex-row gap-10 w-full">
          <Form.Item
            name={"firstName"}
            className="mb-3 flex-1"
            label="First Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={"lastName"}
            className="mb-3 flex-1"
            label="last Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>

        <div className="flex justify-between items-center flex-col lg:flex-row gap-10 w-full">
          <Form.Item
            name={"email"}
            className="mb-3 flex-1"
            label="Email"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={"phoneNumber"}
            className="mb-3 flex-1"
            label="Phone Number"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>

        <div className="flex justify-between items-center flex-col lg:flex-row gap-10 w-full">
          <Form.Item
            name={"password"}
            className="mb-3 flex-1"
            label="Enter Password"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name={"testPassword"}
            className="mb-3 flex-1"
            label="Confirm Password"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
        </div>
        {values?.password !== values?.testPassword ? <p className="text-[14px] text-red-500">{'Password does not match'}</p> : null}
       
        <Button
          loading={isloading}
          disabled={isloading || values?.password !== values?.testPassword}
          htmlType="submit"
          className="!bg-[#9b7c27] text-white hover:!bg-[#001529] !outline-none  font-bold !border-0"
        >
          Create New User
        </Button>
        {/* </div> */}
      </Form>
    </div>
  );
};

export default AdminPage;
