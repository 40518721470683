import React, { useEffect, useState } from "react";
import QuoteComponent from "../../components/QuoteComponent/QuoteComponent";
import adminServices from "../../services/httpServices";
import { Button, Form, Modal, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { currentDateTime } from "../../helpers/helpers";
import NotificationService from "../../NotificationService";

const VisionPgae = () => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [isloading, setisloading] = useState(false);
  const [quoteData, setQuoteData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetch = async () => {
    setisloading(true);
    try {
      const res = await adminServices.MissionVision();
      setQuoteData(res?.data);

      setisloading(false);
    } catch (error) {
  // console.log(error);
      NotificationService.show(error.message, "error");
      setisloading(false);
    }
  };
  useEffect(() => {
    fetch();
  }, [isModalOpen]);

  const editvisionhandler = async () => {
    setisloading(true);
    try {
      const res = await adminServices.EditVision(1, {
        id: 1,
        ...values,
        // mission: "string",
        // vission: "string",
        dateCreated: currentDateTime,
        dateUpdated: currentDateTime,
      });
      setIsModalOpen(false);
      setisloading(false);
    } catch (error) {
      NotificationService.show(error.message, "error");
      setisloading(false);
    }
  };
  return (
    <div>
      <Spin
        spinning={isloading}
        delay={500}
        size="large"
        tip={"Fetching Mission&Vision Data...."}
        className=" text-[#9b7c27] font-bold"
      >
        {quoteData?.map((value, index) => (
          <QuoteComponent key={index} quotedetails={value} />
        ))}
      </Spin>

      
      <div className="w-[30%] mx-auto py-5">
        <button
          className="text-lg rounded-lg px-5 py-2 w-full bg-[#001529] font-bold text-[#9b7c27]"
          onClick={() => setIsModalOpen(true)}
        >
          Edit
        </button>
      </div>

      <Modal
        open={isModalOpen}
        centered={true}
        okText="save"
        cancelText="Make Changes"
        // onOk={actionDeleteHandler}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form
          form={form}
          //   initialValues={initialValues}
          className="bg-gray-50 p-8 rounded border w-full"
          layout="vertical"
          onFinish={() => editvisionhandler()}
        >
          <div className="flex flex-col gap-3">
            <Form.Item
              name={"mission"}
              className="mb-3 flex-1"
              label="Mission"
              rules={[{ required: true }]}
            >
              <TextArea placeholder="Mission" />
            </Form.Item>
            <Form.Item
              name={"vission"}
              className="mb-3 flex-1"
              label="Vision"
              rules={[{ required: true }]}
            >
              <TextArea placeholder="Vision" />
            </Form.Item>
          </div>

          <Form.Item rules={[{ required: true }]} className="mb-1 flex-1">
            <Button
              htmlType="submit"
              className="bg-[#001529] text-white  mt-2"
              // onSubmit={}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default VisionPgae;
