// import type { ThemeConfig } from "antd";

export const antdTheme = {
  token: {
    colorPrimary:'#9b7c27',
    // colorBgContainer: 'white',
    colorBgLayout : 'white'
  },
  components: {
    Layout : {
      headerBg : '#9b7c27',
      // siderBg : '#001529',
      // footerBg : 'yellow',
      // bodyBg: 'red',
      // controlHeightLG : 500
    },
    Menu: {
      collapsedIconSize : 20,
      darkItemHoverBg: '#000c17',
      darkItemSelectedBg : '#9b7c27',
      iconSize : 20
    },
    Table: {
      headerBg : '#001529',
      headerColor : 'white',
      rowExpandedBg : 'blue'
    },

  },
};
