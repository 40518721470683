import { Route, Routes } from "react-router-dom";
import Homepage2 from "./pages/home/Home2";
import LoginPage from "./pages/SignInPage/LoginPage";
import DashBoard from "./pages/DashBoard/DashBoard";
import AdminPage from "./pages/AdminPage/AdminPage";
import NotFoundComponent from "./pages/404Page/NotFoundPage";
import SliderPage from "./pages/SliderPage/SliderPage";
import Board from "./pages/boards/Board";
import ServicesPage from "./pages/servicesPage";
import IndividualAccount from "./pages/RegistrationPage/IndividualAccount";
import VisionPgae from "./pages/visionPage";
import PrivateRoute from "./privateRoute";

function App() {
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<Homepage2 />}>
          <Route index element={<DashBoard />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/slider" element={<SliderPage />} />
          <Route path="/boards" element={<Board />} />

          <Route path="/Post" element={<Board />} />

          <Route path="/IndividualAccount" element={<IndividualAccount />} />
          <Route path="/minor_Account" element={<IndividualAccount />} />
          <Route path="/corporate_Account" element={<IndividualAccount />} />
          <Route path="/estate_Account" element={<IndividualAccount />} />
          <Route path="/joint_Account" element={<IndividualAccount />} />

          <Route path="/vision&Mission" element={<VisionPgae />} />
        </Route>
      </Route>
      <Route path="SignIn" element={<LoginPage />} />
    </Routes>
  );
}

export default App;
